<template>
  <div>
    <h1>Super User Panel</h1>
    <div v-if="!isAuthenticated">
      <form @submit.prevent="authenticate">
        <div>
          <label for="password">Auth:</label>
          <input type="password" v-model="password" required />
        </div>
        <button type="submit">Login</button>
      </form>
    </div>
    <div v-else>
      <!-- Funzioni specifiche per il super user -->
      <p>Welcome to the Super User Panel!</p>
      <div
        v-if="isLoading"
        class="flex-container flex-end-align spinner-container"
      >
        <Spinner id="spinner"></Spinner>
      </div>
      <div v-else>
        <input
          type="button"
          class="btn"
          value="Scarica editors thumbnails"
          @click="dl_imgs"
        />
        <input
          type="button"
          class="btn"
          value="Aggiorna editors Access Token"
          @click="update_access_tokens"
        />
        <input
          type="button"
          class="btn"
          value="Richiedi pagine facebook"
          @click="get_fb_pages"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { ApiEndPoints } from "@/api/Endpoints";
import axios from "axios";
import Spinner from "@/components/Spinner.vue";
import { useStore } from "vuex";

export default {
  components: {
    Spinner,
  },
  data() {
    return {
      password: "",
      isAuthenticated: false,
    };
  },
  methods: {
    authenticate() {
      const superUserPassword = process.env.VUE_APP_SUPER_USER_PASSWORD;
      if (this.password === superUserPassword) {
        this.isAuthenticated = true;
      } else {
        alert("Incorrect password");
      }
    },
    async dl_imgs() {
      this.isLoading = true; // Mostra lo spinner
      try {
        console.log("dl_imgs_started");
        await axios.get(ApiEndPoints.SOCIAL_FB + `/all/update_thumbs`);
        console.log("dl_imgs_completed");
      } catch (error) {
        console.error("Error downloading images:", error);
      } finally {
        this.isLoading = false; // Nascondi lo spinner
      }
    },
  },
  setup() {
    const isLoading = ref(false);
    const store = useStore();

    const update_access_tokens = async () => {
      isLoading.value = true; // Mostra lo spinner
      try {
        console.log("update_access_tokens_started");
        await store.dispatch("social/refreshFacebookTokens");
        console.log("update_access_tokens_completed");
      } catch (error) {
        console.error("Error updating access tokens:", error);
      } finally {
        isLoading.value = false; // Nascondi lo spinner
      }
    };

    const get_fb_pages = async () => {
      isLoading.value = true; // Mostra lo spinner
      try {
        console.log("get_fb_pages_started");
        await store.dispatch("social/getFacebookPages");
        console.log("get_fb_pages_completed");
      } catch (error) {
        console.error("Error getting facebook pages:", error);
      } finally {
        isLoading.value = false; // Nascondi lo spinner
      }
    };

    return {
      ApiEndPoints,
      isLoading,
      update_access_tokens,
      get_fb_pages,
    };
  },
};
</script>

<style scoped>
.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* Assicurati che il contenitore abbia un'altezza, altrimenti non sarà visibile */
}

.btn {
  margin-right: 8px;
  margin-bottom: 8px;
  min-width: 300px;
  padding: 4px 8px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.btn:hover {
  background-color: #0056b3;
}
</style>
